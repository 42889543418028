import * as React from 'react'
import { ContactScreen } from '@src/components/screens/ContactScreen'

const validate = (values: any) => {
  const errors: any = {}
  if (!values.name) {
    errors.name = 'Required'
  } else if (values.name.length > 50) {
    errors.name = 'Must be 50 characters or less'
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.message) {
    errors.message = 'Required'
  } else if (values.message.length < 10) {
    errors.message = 'Must be more than 10 characters.'
  }
  return errors
}

export default (props: any) => <ContactScreen {...props} validate={validate} />
