import React from 'react'
import { navigateTo } from 'gatsby-link'

import '@src/theme/contact.sass'
import autobind from 'autobind-decorator';

const ErrorText: React.SFC<any> = (props) => (
  <span
    style={{
      color: 'tomato',
    }}
  >
    {props.text}
  </span>
)

const MyTextInput: React.SFC<any> = ({
  input,
  label,
  type,
  error,
  ...inputProps
}) => (
  <div className="wrap-input100 rs1-wrap-input100 validate-input">
    <span className="label-input100">{label}</span>
    <input type={type} {...input} {...inputProps} />
    <span className="focus-input100" />
    {error && <ErrorText text={error} />}
  </div>
)
const MyTextArea: React.SFC<any> = ({
  input,
  label,
  type,
  error,
  ...inputProps
}) => (
  <div className="wrap-input100 validate-input">
    <span className="label-input100">{label}</span>
    <textarea type={type} {...input} {...inputProps} />
    <span className="focus-input100" />
    {error && <ErrorText text={error} />}
  </div>
)

const ContactForm: React.SFC<any> = (props) => {
  const { handleSubmit, values, errors, submitting, handleChange } = props
  return (
    // <form className="contact-form validate-form" onSubmit={handleSubmit}>
    <form
      className="contact-form validate-form"
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/thanks"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      {/* <span className="contact-form-title">
        Contact Us
      </span> */}

      <MyTextInput
        name="name"
        type="text"
        label="Name"
        placeholder="your name"
        className="input100"
        value={values.name}
        error={errors.name}
        onChange={handleChange}
      />
      <MyTextInput
        name="email"
        type="email"
        label="Email"
        placeholder="your-email@domain.com"
        className="input100"
        value={values.email}
        error={errors.email}
        onChange={handleChange}
      />
      <MyTextArea
        name="message"
        type="message"
        label="Message"
        placeholder="Anything..."
        className="input100"
        value={values.message}
        error={errors.message}
        onChange={handleChange}
      />

      <div className="container-contact-form-btn">
        <button
          type="submit"
          disabled={submitting}
          className="contact-form-btn"
          onClick={handleSubmit}
        >
          SEND
        </button>
      </div>
    </form>
  )
}

function encode(data: any) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export class ContactScreen extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      values: {
        name: '',
        email: '',
        message: '',
      },
      errors: {},
      // isValid: false
    }
  }

  validate(values: any) {
    if (this.props.validate) {
      return this.props.validate(values)
    } else {
      const errors: any = {}
      if (!values.name) {
        errors.name = '必須'
      } else if (values.name.length > 50) {
        errors.name = '50文字以下でご入力ください'
      }
      if (!values.email) {
        errors.email = '必須'
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = '今一度ご確認ください'
      }
      if (!values.message) {
        errors.message = '必須'
      } else if (values.message.length < 10) {
        errors.message = '10文字以上でご入力ください'
      }
      return errors
    }
  }

  @autobind
  handleChange(e: any) {
    this.setState({
      values: { ...this.state.values, [e.target.name]: e.target.value },
    })
    // const errors = this.validate(this.state.values)
    // if (Object.keys(errors).length === 0) {
    //   this.setState({isValid: true})
    // }
  }

  @autobind
  handleSubmit(e: any) {
    e.preventDefault()

    this.setState({ submitting: true })
    let errors = this.validate(this.state.values)
    // console.lo
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
        submitting: false,
      })
      return
    }
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state.values }),
    })
      .then(() => {
        navigateTo('/thanks')
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        })
        alert(error)
      })

    // .then(function (response) {
    //   console.log(response);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }

  render() {
    return (
      <div className="contactPage">
        <ContactForm
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          values={this.state.values}
          errors={this.state.errors}
          submitting={this.state.submitting}
        />
      </div>
    )
  }
}
